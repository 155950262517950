import * as React from "react"
import Header from "../../components/Header/Header";
import "./../../sass/main.scss"
import Footer from "../../components/Footer/Footer";
let isFrench = process.env.LANG === "FR";

const IndexPage = () => {


    return (
        <>
            <Header title={"RONA inc. Careers HR Documents"}
                    description={"RONA inc. Careers HR Documents"} hidden={false}
                    lang={process.env.LANG ? process.env.LANG : "en"}
                    lang_link={isFrench ? `${process.env.EN_URL}/docs` : `${process.env.FR_URL}/docs`}
                    bodyClasses={"page-docs"}/>
            <main id={"main"}>
                <section className={"container-xxl"}>
                    <h1 class={"mt4"}>Recruitment Tool Kit / Coffre à outils recrutement</h1>
                    <div className={"r ac mt4"}>
                        <a target={"_blank"}  class={"mx3"} rel={"nofollow"}
                           href="https://fxbsmrona.service-now.com/myhr/?id=kb_article&sysparm_article=KB0034027" className={"mt1"}>
                            <button
                                className={'button'}>English</button>
                        </a>
                        <a target={"_blank"}  class={"mx3"} rel={"nofollow"}
                           href="https://fxbsmrona.service-now.com/myhr/?id=kb_article&sysparm_article=KB0034026" className={"mt1"}>
                            <button
                                className={'button'}>Français</button>
                        </a>
                        {/*<h4 className={"ml4 my2"}>{isFrench ? `VidCruiter - Aide à la tâche (Magasins)` : `Job Aid (Stores)`}</h4>*/}
                    </div>
                    {/*<div className={"r ac"}>*/}
                    {/*    */}
                    {/*    <h4 className={"ml4 my2"}>{isFrench ? `VidCruiter - Aide à la tâche (Centres de distribution)` : `Job Aid (Distribution centers)`}</h4>*/}
                    {/*</div>*/}
                    {/*<div className={"r ac"}>*/}
                    {/*    <a target={"_blank"} rel={"nofollow"}*/}
                    {/*       href={isFrench ? jobAidHTagsFR : jobAidHTags} className={"mt1"}>*/}
                    {/*        <button*/}
                    {/*            className={'button'}>{isFrench ? `Télécharger` : `Download`}</button>*/}
                    {/*    </a>*/}
                    {/*    <h4 className={"ml4 my2"}>{isFrench ? `VidCruiter - Aide à la tâche - TAGS pour les candidats` : `Job Aid - TAGS for candidates`}</h4>*/}
                    {/*</div>*/}
                    {/*<div className={"r ac"}>*/}
                    {/*    <a target={"_blank"} rel={"nofollow"}*/}
                    {/*       href={isFrench ? simplifiedInterviewGuideFR : simplifiedInterviewGuide}*/}
                    {/*       className={"mt1"}>*/}
                    {/*        <button*/}
                    {/*            className={'button'}>{isFrench ? `Télécharger` : `Download`}</button>*/}
                    {/*    </a>*/}
                    {/*    <h4 className={"ml4 my2"}>{isFrench ? `Guide d'entrevue  simplifié` : `Simplified Interview Guide`}</h4>*/}
                    {/*</div>*/}
                    {/*<div className={"r ac"}>*/}
                    {/*    <a target={"_blank"} rel={"nofollow"}*/}
                    {/*       href={isFrench ? managerInterviewGuideFR : managerInterviewGuide}*/}
                    {/*       className={"mt1"}>*/}
                    {/*        <button*/}
                    {/*            className={'button'}>{isFrench ? `Télécharger` : `Download`}</button>*/}
                    {/*    </a>*/}
                    {/*    <h4 className={"ml4 my2"}>{isFrench ? `Guide entrevue de gestionnaire` : `Manager Interview Guide`}</h4>*/}
                    {/*</div>*/}
                    {/*<div className={"r ac"}>*/}
                    {/*    <a target={"_blank"} rel={"nofollow"}*/}
                    {/*       href={isFrench ? orderPickupGuideFR : orderPickupGuide} className={"mt1"}>*/}
                    {/*        <button*/}
                    {/*            className={'button'}>{isFrench ? `Télécharger` : `Download`}</button>*/}
                    {/*    </a>*/}
                    {/*    <h4 className={"ml4 my2"}>{isFrench ? `Guide préparateur de commande (CD)` : `Order picker Guide DC`}</h4>*/}
                    {/*</div>*/}
                    {/*<div className={"r ac"}>*/}
                    {/*    <a target={"_blank"} rel={"nofollow"}*/}
                    {/*       href={isFrench ? operationsManagerGuideDCFR : operationsManagerGuideDC}*/}
                    {/*       className={"mt1"}>*/}
                    {/*        <button*/}
                    {/*            className={'button'}>{isFrench ? `Télécharger` : `Download`}</button>*/}
                    {/*    </a>*/}
                    {/*    <h4 className={"ml4 my2"}>{isFrench ? `Guide superviseur des opérations (CD)` : `Operations manager Guide DC`}</h4>*/}
                    {/*</div>*/}
                    {/*<div className={"r ac"}>*/}
                    {/*    <a target={"_blank"} rel={"nofollow"}*/}
                    {/*       href={isFrench ? StoresVidCruiterUserGuideFR : StoresVidCruiterUserGuide}*/}
                    {/*       className={"mt1"}>*/}
                    {/*        <button*/}
                    {/*            className={'button'}>{isFrench ? `Télécharger` : `Download`}</button>*/}
                    {/*    </a>*/}
                    {/*    <h4 className={"ml4 my2"}>{isFrench ? `Guide d'utilisateur VidCruiter ASA (Magasin)` : `VidCruiter User Guide_ASA (Stores)`}</h4>*/}
                    {/*</div>*/}
                    {/* <div className={"r ac"}>*/}
                    {/*    <a target={"_blank"} rel={"nofollow"}*/}
                    {/*       href={isFrench ? DCVidCruiterUserGuideFR : DCVidCruiterUserGuide}*/}
                    {/*       className={"mt1"}>*/}
                    {/*        <button*/}
                    {/*            className={'button'}>{isFrench ? `Télécharger` : `Download`}</button>*/}
                    {/*    </a>*/}
                    {/*    <h4 className={"ml4 my2"}>{isFrench ? `Guide d'utilisateur VidCruiter ASA (CD)` : `VidCruiter User Guide   ASA (DC)`}</h4>*/}
                    {/*</div>*/}
                    {/*<div className={"r ac"}>*/}
                    {/*    <a target={"_blank"} rel={"nofollow"}*/}
                    {/*       href={isFrench ? vidcruiterFR : vidcruiter} className={"mt1"}>*/}
                    {/*        <button*/}
                    {/*            className={'button'}>{isFrench ? `Télécharger` : `Download`}</button>*/}
                    {/*    </a>*/}
                    {/*    <h4 className={"ml4 my2"}>{isFrench ? `VidCruiter` : `VidCruiter`}</h4>*/}
                    {/*</div>*/}

                </section>
            </main>
            <Footer lang={process.env.LANG ? process.env.LANG : "en"}/>
        </>
    )
}

export default IndexPage;
